html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,font,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td{margin:0;padding:0;border:0;outline:0;font-weight:inherit;font-style:inherit;font-size:100%;font-family:inherit;}
table{border-collapse:collapse;border-spacing:0;}

@bashhub-green: #009244;
@import "npm://bootstrap/less/bootstrap.less";
@import (less) "npm://bootflat/bootflat/css/bootflat.css";

.bashhub {

  h1, h2, h3, h4, h5 {
    font-weight: 200;
  }

  .container {
    max-width: 1010px;
    padding: 0 15px;
    width: 100%;
  }

  .navbar-default .navbar-collapse {
    border-color: #ffffff;
  }

  .navbar-default .navbar-toggle .icon-bar {
    background-color: @bashhub-green;
  }

  .navbar-default .navbar-toggle {
    background-color: #ffffff;
    border-color: @bashhub-green;

    &:hover, &:focus {
      background-color: @bashhub-green;
      border-color: @bashhub-green;
      .icon-bar {
        background-color: #ffffff;
      }
    }
  }



  .navbar {

    background-color: #ffffff;
    border-color: #ffffff;
    font-size: 14px;

    @media (min-width: 768px) {
      .navbar-nav {
        margin: 10px 0 0 50px;
      }
    }

    .navbar-header .navbar-brand {
      line-height: 50px;
      padding: 0 15px;
    }

    .nav-logo {
      display: inline-block;
      height: 35px;
    }

    .nav a {
      font-size: 15px;
      color: #777;
    }

    .nav a:hover, .nav a:focus {
      color: #555555;

      @media (max-width: 767px) {
        color: #ffffff;
        background-color: @bashhub-green;
      }

    }
  }
}
